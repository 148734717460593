export const searchIndex = [
  {
    "title": "Supporting the Docs",
    "headers": [
      {
        "level": 2,
        "title": "Contributing via Issues",
        "slug": "contributing-via-issues",
        "link": "#contributing-via-issues",
        "children": []
      },
      {
        "level": 2,
        "title": "Contributing via PRs",
        "slug": "contributing-via-prs",
        "link": "#contributing-via-prs",
        "children": []
      },
      {
        "level": 2,
        "title": "How to Contribute",
        "slug": "how-to-contribute",
        "link": "#how-to-contribute",
        "children": [
          {
            "level": 3,
            "title": "Simple step-by-step guide",
            "slug": "simple-step-by-step-guide",
            "link": "#simple-step-by-step-guide",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Tips",
        "slug": "tips",
        "link": "#tips",
        "children": []
      }
    ],
    "path": "/docs/contributing.html",
    "pathLocale": "/",
    "extraFields": [
      "Supporting the Docs"
    ]
  },
  {
    "title": "Developer Mode",
    "headers": [
      {
        "level": 2,
        "title": "Reset GBB Flags",
        "slug": "reset-gbb-flags",
        "link": "#reset-gbb-flags",
        "children": [
          {
            "level": 3,
            "title": "Entering Developer Mode",
            "slug": "entering-developer-mode",
            "link": "#entering-developer-mode",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/exiting-developer-mode.html",
    "pathLocale": "/",
    "extraFields": [
      "Developer Mode"
    ]
  },
  {
    "title": "FAQ",
    "headers": [
      {
        "level": 2,
        "title": "General questions",
        "slug": "general-questions",
        "link": "#general-questions",
        "children": [
          {
            "level": 3,
            "title": "What OS should I use?",
            "slug": "what-os-should-i-use",
            "link": "#what-os-should-i-use",
            "children": []
          },
          {
            "level": 3,
            "title": "Should I use rw-legacy or full rom?",
            "slug": "should-i-use-rw-legacy-or-full-rom",
            "link": "#should-i-use-rw-legacy-or-full-rom",
            "children": []
          },
          {
            "level": 3,
            "title": "My internal keyboard doesn't work, can I use an external keyboard to get into developer mode?",
            "slug": "my-internal-keyboard-doesn-t-work-can-i-use-an-external-keyboard-to-get-into-developer-mode",
            "link": "#my-internal-keyboard-doesn-t-work-can-i-use-an-external-keyboard-to-get-into-developer-mode",
            "children": []
          },
          {
            "level": 3,
            "title": "I just want to boot Linux from USB on my Chromebook, what do I need to do?",
            "slug": "i-just-want-to-boot-linux-from-usb-on-my-chromebook-what-do-i-need-to-do",
            "link": "#i-just-want-to-boot-linux-from-usb-on-my-chromebook-what-do-i-need-to-do",
            "children": []
          },
          {
            "level": 3,
            "title": "Can I disable power-on when I open my Chromebook?",
            "slug": "can-i-disable-power-on-when-i-open-my-chromebook",
            "link": "#can-i-disable-power-on-when-i-open-my-chromebook",
            "children": []
          },
          {
            "level": 3,
            "title": "My Chromebook powers back on when I turn it off. How can I fix this?",
            "slug": "my-chromebook-powers-back-on-when-i-turn-it-off-how-can-i-fix-this",
            "link": "#my-chromebook-powers-back-on-when-i-turn-it-off-how-can-i-fix-this",
            "children": []
          },
          {
            "level": 3,
            "title": "I just installed the UEFI firmware, and now my device boots to a black screen that says 'shell' - what do I do?",
            "slug": "i-just-installed-the-uefi-firmware-and-now-my-device-boots-to-a-black-screen-that-says-shell-what-do-i-do",
            "link": "#i-just-installed-the-uefi-firmware-and-now-my-device-boots-to-a-black-screen-that-says-shell-what-do-i-do",
            "children": []
          },
          {
            "level": 3,
            "title": "Should I install 32 or 64-bit OS?",
            "slug": "should-i-install-32-or-64-bit-os",
            "link": "#should-i-install-32-or-64-bit-os",
            "children": []
          },
          {
            "level": 3,
            "title": "I'm running Legacy boot firmware now - can I switch to the UEFI firmware?",
            "slug": "i-m-running-legacy-boot-firmware-now-can-i-switch-to-the-uefi-firmware",
            "link": "#i-m-running-legacy-boot-firmware-now-can-i-switch-to-the-uefi-firmware",
            "children": []
          },
          {
            "level": 3,
            "title": "Help! I can't exit Developer Mode",
            "slug": "help-i-can-t-exit-developer-mode",
            "link": "#help-i-can-t-exit-developer-mode",
            "children": []
          },
          {
            "level": 3,
            "title": "Can I modify the fan speed?",
            "slug": "can-i-modify-the-fan-speed",
            "link": "#can-i-modify-the-fan-speed",
            "children": []
          },
          {
            "level": 3,
            "title": "Can I overclock the fan?",
            "slug": "can-i-overclock-the-fan",
            "link": "#can-i-overclock-the-fan",
            "children": []
          },
          {
            "level": 3,
            "title": "I want to buy a Chromebook to run Windows or Linux or macOS -- which should I buy?",
            "slug": "i-want-to-buy-a-chromebook-to-run-windows-or-linux-or-macos-which-should-i-buy",
            "link": "#i-want-to-buy-a-chromebook-to-run-windows-or-linux-or-macos-which-should-i-buy",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Windows questions",
        "slug": "windows-questions",
        "link": "#windows-questions",
        "children": [
          {
            "level": 3,
            "title": "Should I use Windows 10 or 11?",
            "slug": "should-i-use-windows-10-or-11",
            "link": "#should-i-use-windows-10-or-11",
            "children": []
          },
          {
            "level": 3,
            "title": "Can I pay for Windows drivers using PayPal?",
            "slug": "can-i-pay-for-windows-drivers-using-paypal",
            "link": "#can-i-pay-for-windows-drivers-using-paypal",
            "children": []
          },
          {
            "level": 3,
            "title": "My balance shows zero on coolstars portal, what should I do?",
            "slug": "my-balance-shows-zero-on-coolstars-portal-what-should-i-do",
            "link": "#my-balance-shows-zero-on-coolstars-portal-what-should-i-do",
            "children": []
          },
          {
            "level": 3,
            "title": "How do I buy the Windows audio/Thunderbolt drivers?",
            "slug": "how-do-i-buy-the-windows-audio-thunderbolt-drivers",
            "link": "#how-do-i-buy-the-windows-audio-thunderbolt-drivers",
            "children": []
          },
          {
            "level": 3,
            "title": "Can I get AVS or SOF drivers for free?",
            "slug": "can-i-get-avs-or-sof-drivers-for-free",
            "link": "#can-i-get-avs-or-sof-drivers-for-free",
            "children": []
          },
          {
            "level": 3,
            "title": "Why is there a license error in Device Manager after installing audio drivers?",
            "slug": "why-is-there-a-license-error-in-device-manager-after-installing-audio-drivers",
            "link": "#why-is-there-a-license-error-in-device-manager-after-installing-audio-drivers",
            "children": []
          },
          {
            "level": 3,
            "title": "Will the audio driver still work when re-installing Windows?",
            "slug": "will-the-audio-driver-still-work-when-re-installing-windows",
            "link": "#will-the-audio-driver-still-work-when-re-installing-windows",
            "children": []
          },
          {
            "level": 3,
            "title": "I upgraded my Chromebooks' internal drive and my audio/Thunderbolt 4 license is not working, what do I do?",
            "slug": "i-upgraded-my-chromebooks-internal-drive-and-my-audio-thunderbolt-4-license-is-not-working-what-do-i-do",
            "link": "#i-upgraded-my-chromebooks-internal-drive-and-my-audio-thunderbolt-4-license-is-not-working-what-do-i-do",
            "children": []
          },
          {
            "level": 3,
            "title": "Can I use one driver license across multiple devices?",
            "slug": "can-i-use-one-driver-license-across-multiple-devices",
            "link": "#can-i-use-one-driver-license-across-multiple-devices",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Linux Questions",
        "slug": "linux-questions",
        "link": "#linux-questions",
        "children": [
          {
            "level": 3,
            "title": "How come audio isn't working on Ubuntu / Ubuntu forks?",
            "slug": "how-come-audio-isn-t-working-on-ubuntu-ubuntu-forks",
            "link": "#how-come-audio-isn-t-working-on-ubuntu-ubuntu-forks",
            "children": []
          },
          {
            "level": 3,
            "title": "Why are Ubuntu/Ubuntu based distros not supported?",
            "slug": "why-are-ubuntu-ubuntu-based-distros-not-supported",
            "link": "#why-are-ubuntu-ubuntu-based-distros-not-supported",
            "children": []
          },
          {
            "level": 3,
            "title": "What Linux distros are recommended?",
            "slug": "what-linux-distros-are-recommended",
            "link": "#what-linux-distros-are-recommended",
            "children": []
          },
          {
            "level": 3,
            "title": "How can I get audio working under Linux?",
            "slug": "how-can-i-get-audio-working-under-linux",
            "link": "#how-can-i-get-audio-working-under-linux",
            "children": []
          },
          {
            "level": 3,
            "title": "How do I get my top row keys on Linux to act like how they did on chromeOS?",
            "slug": "how-do-i-get-my-top-row-keys-on-linux-to-act-like-how-they-did-on-chromeos",
            "link": "#how-do-i-get-my-top-row-keys-on-linux-to-act-like-how-they-did-on-chromeos",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Hackintosh questions",
        "slug": "hackintosh-questions",
        "link": "#hackintosh-questions",
        "children": [
          {
            "level": 3,
            "title": "Can I hackintosh my Celeron/Pentium CPU?",
            "slug": "can-i-hackintosh-my-celeron-pentium-cpu",
            "link": "#can-i-hackintosh-my-celeron-pentium-cpu",
            "children": []
          },
          {
            "level": 3,
            "title": "Can I hackintosh an AMD CPU?",
            "slug": "can-i-hackintosh-an-amd-cpu",
            "link": "#can-i-hackintosh-an-amd-cpu",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/faq.html",
    "pathLocale": "/",
    "extraFields": [
      "FAQ"
    ]
  },
  {
    "title": "Terminology",
    "headers": [],
    "path": "/docs/terminology.html",
    "pathLocale": "/",
    "extraFields": [
      "Terminology"
    ]
  },
  {
    "title": "Bug Reporting",
    "headers": [],
    "path": "/docs/debugging/bugreport.html",
    "pathLocale": "/",
    "extraFields": [
      "Bug Reporting"
    ]
  },
  {
    "title": "Debugging",
    "headers": [
      {
        "level": 2,
        "title": "Grabbing Logs",
        "slug": "grabbing-logs",
        "link": "#grabbing-logs",
        "children": []
      },
      {
        "level": 2,
        "title": "Firmware",
        "slug": "firmware",
        "link": "#firmware",
        "children": []
      },
      {
        "level": 2,
        "title": "SuzyQ Debug Cable",
        "slug": "suzyq-debug-cable",
        "link": "#suzyq-debug-cable",
        "children": []
      },
      {
        "level": 2,
        "title": "ACPI and Linux kernelspace",
        "slug": "acpi-and-linux-kernelspace",
        "link": "#acpi-and-linux-kernelspace",
        "children": []
      },
      {
        "level": 2,
        "title": "flashrom",
        "slug": "flashrom",
        "link": "#flashrom",
        "children": [
          {
            "level": 3,
            "title": "Read flash",
            "slug": "read-flash",
            "link": "#read-flash",
            "children": []
          },
          {
            "level": 3,
            "title": "Write flash",
            "slug": "write-flash",
            "link": "#write-flash",
            "children": []
          },
          {
            "level": 3,
            "title": "Write protection",
            "slug": "write-protection",
            "link": "#write-protection",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "gsctool",
        "slug": "gsctool",
        "link": "#gsctool",
        "children": []
      }
    ],
    "path": "/docs/debugging/",
    "pathLocale": "/",
    "extraFields": [
      "Debugging"
    ]
  },
  {
    "title": "Types of Firmware",
    "headers": [
      {
        "level": 2,
        "title": "RW_LEGACY",
        "slug": "rw-legacy",
        "link": "#rw-legacy",
        "children": []
      },
      {
        "level": 2,
        "title": "(UEFI) Full ROM",
        "slug": "uefi-full-rom",
        "link": "#uefi-full-rom",
        "children": []
      }
    ],
    "path": "/docs/firmware/about.html",
    "pathLocale": "/",
    "extraFields": [
      "Types of Firmware"
    ]
  },
  {
    "title": "Disabling Write Protect via Battery",
    "headers": [
      {
        "level": 2,
        "title": "Step 1: Open the device and disconnect the battery",
        "slug": "step-1-open-the-device-and-disconnect-the-battery",
        "link": "#step-1-open-the-device-and-disconnect-the-battery",
        "children": []
      },
      {
        "level": 2,
        "title": "Step 2: Boot using AC power, and flash",
        "slug": "step-2-boot-using-ac-power-and-flash",
        "link": "#step-2-boot-using-ac-power-and-flash",
        "children": []
      }
    ],
    "path": "/docs/firmware/battery.html",
    "pathLocale": "/",
    "extraFields": [
      "Disabling Write Protect via Battery"
    ]
  },
  {
    "title": "Compiling Custom Firmware",
    "headers": [
      {
        "level": 2,
        "title": "Cloning & Building ROMs",
        "slug": "cloning-building-roms",
        "link": "#cloning-building-roms",
        "children": []
      }
    ],
    "path": "/docs/firmware/compiling-coreboot.html",
    "pathLocale": "/",
    "extraFields": [
      "Compiling Custom Firmware"
    ]
  },
  {
    "title": "Developer Mode",
    "headers": [
      {
        "level": 2,
        "title": "Enabling Developer Mode",
        "slug": "enabling-developer-mode",
        "link": "#enabling-developer-mode",
        "children": [
          {
            "level": 3,
            "title": "Chromebooks/Chromeboxes",
            "slug": "chromebooks-chromeboxes",
            "link": "#chromebooks-chromeboxes",
            "children": []
          },
          {
            "level": 3,
            "title": "Tablets",
            "slug": "tablets",
            "link": "#tablets",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/developer-mode.html",
    "pathLocale": "/",
    "extraFields": [
      "Developer Mode"
    ]
  },
  {
    "title": "Flashing Custom Firmware",
    "headers": [
      {
        "level": 2,
        "title": "Booting",
        "slug": "booting",
        "link": "#booting",
        "children": [
          {
            "level": 3,
            "title": "UEFI",
            "slug": "uefi",
            "link": "#uefi",
            "children": []
          },
          {
            "level": 3,
            "title": "RW_LEGACY",
            "slug": "rw-legacy",
            "link": "#rw-legacy",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/flashing-firmware.html",
    "pathLocale": "/",
    "extraFields": [
      "Flashing Custom Firmware"
    ]
  },
  {
    "title": "Flashing Custom Firmware",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Asking for help properly",
        "slug": "asking-for-help-properly",
        "link": "#asking-for-help-properly",
        "children": []
      }
    ],
    "path": "/docs/firmware/",
    "pathLocale": "/",
    "extraFields": [
      "Flashing Custom Firmware"
    ]
  },
  {
    "title": "Known Issues",
    "headers": [
      {
        "level": 2,
        "title": "Devices",
        "slug": "devices",
        "link": "#devices",
        "children": []
      },
      {
        "level": 2,
        "title": "Firmware",
        "slug": "firmware",
        "link": "#firmware",
        "children": []
      }
    ],
    "path": "/docs/firmware/known-issues.html",
    "pathLocale": "/",
    "extraFields": [
      "Known Issues"
    ]
  },
  {
    "title": "Manually Flashing ROMs",
    "headers": [],
    "path": "/docs/firmware/manually-flashing.html",
    "pathLocale": "/",
    "extraFields": [
      "Manually Flashing ROMs"
    ]
  },
  {
    "title": "Recovery Mode",
    "headers": [
      {
        "level": 2,
        "title": "Entering Recovery Mode",
        "slug": "entering-recovery-mode",
        "link": "#entering-recovery-mode",
        "children": [
          {
            "level": 3,
            "title": "Chromebooks",
            "slug": "chromebooks",
            "link": "#chromebooks",
            "children": []
          },
          {
            "level": 3,
            "title": "Chromeboxes",
            "slug": "chromeboxes",
            "link": "#chromeboxes",
            "children": []
          },
          {
            "level": 3,
            "title": "Tablets",
            "slug": "tablets",
            "link": "#tablets",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/recovery-mode.html",
    "pathLocale": "/",
    "extraFields": [
      "Recovery Mode"
    ]
  },
  {
    "title": "Supported Devices and Platforms",
    "headers": [
      {
        "level": 2,
        "title": "Determining Device Support",
        "slug": "determining-device-support",
        "link": "#determining-device-support",
        "children": [
          {
            "level": 3,
            "title": "OS Support",
            "slug": "os-support",
            "link": "#os-support",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Supported Platforms",
        "slug": "supported-platforms",
        "link": "#supported-platforms",
        "children": []
      },
      {
        "level": 2,
        "title": "Firmware and OS Support",
        "slug": "firmware-and-os-support",
        "link": "#firmware-and-os-support",
        "children": []
      }
    ],
    "path": "/docs/firmware/supported-devices.html",
    "pathLocale": "/",
    "extraFields": [
      "Supported Devices and Platforms"
    ]
  },
  {
    "title": "Disabling write protect with a SuzyQable",
    "headers": [
      {
        "level": 2,
        "title": "Step 1: Enabling Closed Case Debugging (CCD)",
        "slug": "step-1-enabling-closed-case-debugging-ccd",
        "link": "#step-1-enabling-closed-case-debugging-ccd",
        "children": []
      },
      {
        "level": 2,
        "title": "Step 2: Disable Write Protect",
        "slug": "step-2-disable-write-protect",
        "link": "#step-2-disable-write-protect",
        "children": []
      }
    ],
    "path": "/docs/firmware/suzyq.html",
    "pathLocale": "/",
    "extraFields": [
      "Disabling write protect with a SuzyQable"
    ]
  },
  {
    "title": "Finding System Info",
    "headers": [
      {
        "level": 2,
        "title": "System Architecture",
        "slug": "system-architecture",
        "link": "#system-architecture",
        "children": [
          {
            "level": 3,
            "title": "How to find your architecture",
            "slug": "how-to-find-your-architecture",
            "link": "#how-to-find-your-architecture",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Board Name",
        "slug": "board-name",
        "link": "#board-name",
        "children": [
          {
            "level": 3,
            "title": "How to find your board name",
            "slug": "how-to-find-your-board-name",
            "link": "#how-to-find-your-board-name",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "CPU Model and generation",
        "slug": "cpu-model-and-generation",
        "link": "#cpu-model-and-generation",
        "children": [
          {
            "level": 3,
            "title": "How to find your CPU model",
            "slug": "how-to-find-your-cpu-model",
            "link": "#how-to-find-your-cpu-model",
            "children": []
          },
          {
            "level": 3,
            "title": "How to find your CPU generation",
            "slug": "how-to-find-your-cpu-generation",
            "link": "#how-to-find-your-cpu-generation",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/system-info.html",
    "pathLocale": "/",
    "extraFields": [
      "Finding System Info"
    ]
  },
  {
    "title": "Disabling RO firmware protection on Ti50",
    "headers": [
      {
        "level": 2,
        "title": "Step 1: Verify the device has a Ti50",
        "slug": "step-1-verify-the-device-has-a-ti50",
        "link": "#step-1-verify-the-device-has-a-ti50",
        "children": []
      },
      {
        "level": 2,
        "title": "Step 2: Disable RO verification",
        "slug": "step-2-disable-ro-verification",
        "link": "#step-2-disable-ro-verification",
        "children": []
      },
      {
        "level": 2,
        "title": "Recovering a device bricked due to RO verification",
        "slug": "recovering-a-device-bricked-due-to-ro-verification",
        "link": "#recovering-a-device-bricked-due-to-ro-verification",
        "children": [
          {
            "level": 3,
            "title": "Chromebooks",
            "slug": "chromebooks",
            "link": "#chromebooks",
            "children": []
          },
          {
            "level": 3,
            "title": "Chromeboxes",
            "slug": "chromeboxes",
            "link": "#chromeboxes",
            "children": []
          },
          {
            "level": 3,
            "title": "Tablets",
            "slug": "tablets",
            "link": "#tablets",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/ti50-ap-ro.html",
    "pathLocale": "/",
    "extraFields": [
      "Disabling RO firmware protection on Ti50"
    ]
  },
  {
    "title": "Updating Custom Firmware",
    "headers": [],
    "path": "/docs/firmware/updating-firmware.html",
    "pathLocale": "/",
    "extraFields": [
      "Updating Custom Firmware"
    ]
  },
  {
    "title": "Installing Ventoy to a USB drive with chromeOS",
    "headers": [
      {
        "level": 2,
        "title": "Backing up your drive",
        "slug": "backing-up-your-drive",
        "link": "#backing-up-your-drive",
        "children": []
      },
      {
        "level": 2,
        "title": "Downloading Ventoy",
        "slug": "downloading-ventoy",
        "link": "#downloading-ventoy",
        "children": []
      },
      {
        "level": 2,
        "title": "Extracting Ventoy",
        "slug": "extracting-ventoy",
        "link": "#extracting-ventoy",
        "children": []
      },
      {
        "level": 2,
        "title": "Starting Ventoy",
        "slug": "starting-ventoy",
        "link": "#starting-ventoy",
        "children": []
      },
      {
        "level": 2,
        "title": "Installing Ventoy to USB",
        "slug": "installing-ventoy-to-usb",
        "link": "#installing-ventoy-to-usb",
        "children": []
      },
      {
        "level": 2,
        "title": "Adding OS images",
        "slug": "adding-os-images",
        "link": "#adding-os-images",
        "children": []
      }
    ],
    "path": "/docs/firmware/ventoy.html",
    "pathLocale": "/",
    "extraFields": [
      "Installing Ventoy to a USB drive with chromeOS"
    ]
  },
  {
    "title": "Disabling Write Protect",
    "headers": [],
    "path": "/docs/firmware/write-protect.html",
    "pathLocale": "/",
    "extraFields": [
      "Disabling Write Protect"
    ]
  },
  {
    "title": "Making a Bootable USB",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Downloading a ISO",
        "slug": "downloading-a-iso",
        "link": "#downloading-a-iso",
        "children": []
      },
      {
        "level": 2,
        "title": "Flashing using Rufus (Windows)",
        "slug": "flashing-using-rufus-windows",
        "link": "#flashing-using-rufus-windows",
        "children": []
      },
      {
        "level": 2,
        "title": "Flashing using Ventoy (Windows/Linux/macOS)",
        "slug": "flashing-using-ventoy-windows-linux-macos",
        "link": "#flashing-using-ventoy-windows-linux-macos",
        "children": []
      },
      {
        "level": 2,
        "title": "Flashing with dd (Linux/macOS)",
        "slug": "flashing-with-dd-linux-macos",
        "link": "#flashing-with-dd-linux-macos",
        "children": []
      }
    ],
    "path": "/docs/installing/bootableusb.html",
    "pathLocale": "/",
    "extraFields": [
      "Making a Bootable USB"
    ]
  },
  {
    "title": "Notes for non-standard Linux distributions",
    "headers": [
      {
        "level": 2,
        "title": "Audio",
        "slug": "audio",
        "link": "#audio",
        "children": [
          {
            "level": 3,
            "title": "alsa-ucm2 for Chromebooks",
            "slug": "alsa-ucm2-for-chromebooks",
            "link": "#alsa-ucm2-for-chromebooks",
            "children": []
          },
          {
            "level": 3,
            "title": "Audio setup modprobes",
            "slug": "audio-setup-modprobes",
            "link": "#audio-setup-modprobes",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Keyboard mapping",
        "slug": "keyboard-mapping",
        "link": "#keyboard-mapping",
        "children": [
          {
            "level": 3,
            "title": "libinput configuration",
            "slug": "libinput-configuration",
            "link": "#libinput-configuration",
            "children": []
          },
          {
            "level": 3,
            "title": "keyd keymap",
            "slug": "keyd-keymap",
            "link": "#keyd-keymap",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/installing/distros.html",
    "pathLocale": "/",
    "extraFields": [
      "Notes for non-standard Linux distributions"
    ]
  },
  {
    "title": "Partitioning Instructions for Dual Booting",
    "headers": [
      {
        "level": 2,
        "title": "Using CRAP",
        "slug": "using-crap",
        "link": "#using-crap",
        "children": []
      },
      {
        "level": 2,
        "title": "Installing Linux",
        "slug": "installing-linux",
        "link": "#installing-linux",
        "children": []
      },
      {
        "level": 2,
        "title": "Reverting",
        "slug": "reverting",
        "link": "#reverting",
        "children": []
      }
    ],
    "path": "/docs/installing/dualboot.html",
    "pathLocale": "/",
    "extraFields": [
      "Partitioning Instructions for Dual Booting"
    ]
  },
  {
    "title": "Using Ectool",
    "headers": [
      {
        "level": 2,
        "title": "Installing Ectool",
        "slug": "installing-ectool",
        "link": "#installing-ectool",
        "children": []
      },
      {
        "level": 2,
        "title": "Uses",
        "slug": "uses",
        "link": "#uses",
        "children": []
      }
    ],
    "path": "/docs/installing/ectool.html",
    "pathLocale": "/",
    "extraFields": [
      "Using Ectool"
    ]
  },
  {
    "title": "Installing an OS",
    "headers": [
      {
        "level": 2,
        "title": "Windows",
        "slug": "windows",
        "link": "#windows",
        "children": [
          {
            "level": 3,
            "title": "Pros of Windows",
            "slug": "pros-of-windows",
            "link": "#pros-of-windows",
            "children": []
          },
          {
            "level": 3,
            "title": "Cons of Windows",
            "slug": "cons-of-windows",
            "link": "#cons-of-windows",
            "children": []
          },
          {
            "level": 3,
            "title": "Installing Windows →",
            "slug": "installing-windows-→",
            "link": "#installing-windows-→",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Linux",
        "slug": "linux",
        "link": "#linux",
        "children": [
          {
            "level": 3,
            "title": "Pros of Linux",
            "slug": "pros-of-linux",
            "link": "#pros-of-linux",
            "children": []
          },
          {
            "level": 3,
            "title": "Cons of Linux",
            "slug": "cons-of-linux",
            "link": "#cons-of-linux",
            "children": []
          },
          {
            "level": 3,
            "title": "Installing Linux →",
            "slug": "installing-linux-→",
            "link": "#installing-linux-→",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "macOS",
        "slug": "macos",
        "link": "#macos",
        "children": [
          {
            "level": 3,
            "title": "Pros of macOS",
            "slug": "pros-of-macos",
            "link": "#pros-of-macos",
            "children": []
          },
          {
            "level": 3,
            "title": "Cons of macOS",
            "slug": "cons-of-macos",
            "link": "#cons-of-macos",
            "children": []
          },
          {
            "level": 3,
            "title": "Installing macOS →",
            "slug": "installing-macos-→",
            "link": "#installing-macos-→",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/installing/",
    "pathLocale": "/",
    "extraFields": [
      "Installing an OS"
    ]
  },
  {
    "title": "Installing Linux",
    "headers": [
      {
        "level": 2,
        "title": "Recommended Distributions",
        "slug": "recommended-distributions",
        "link": "#recommended-distributions",
        "children": []
      },
      {
        "level": 2,
        "title": "Installation",
        "slug": "installation",
        "link": "#installation",
        "children": []
      },
      {
        "level": 2,
        "title": "Fixing Audio",
        "slug": "fixing-audio",
        "link": "#fixing-audio",
        "children": [
          {
            "level": 3,
            "title": "Prerequisites",
            "slug": "prerequisites",
            "link": "#prerequisites",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/installing/installing-linux.html",
    "pathLocale": "/",
    "extraFields": [
      "Installing Linux"
    ]
  },
  {
    "title": "Installing macOS",
    "headers": [
      {
        "level": 2,
        "title": "Tested Devices",
        "slug": "tested-devices",
        "link": "#tested-devices",
        "children": []
      },
      {
        "level": 2,
        "title": "Custom Firmware",
        "slug": "custom-firmware",
        "link": "#custom-firmware",
        "children": []
      },
      {
        "level": 2,
        "title": "Installation",
        "slug": "installation",
        "link": "#installation",
        "children": [
          {
            "level": 3,
            "title": "config.plist Fixes",
            "slug": "config-plist-fixes",
            "link": "#config-plist-fixes",
            "children": []
          },
          {
            "level": 3,
            "title": "SSDTs and ACPI Fixes",
            "slug": "ssdts-and-acpi-fixes",
            "link": "#ssdts-and-acpi-fixes",
            "children": []
          },
          {
            "level": 3,
            "title": "Kexts",
            "slug": "kexts",
            "link": "#kexts",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/installing/installing-macos.html",
    "pathLocale": "/",
    "extraFields": [
      "Installing macOS"
    ]
  },
  {
    "title": "Installing Windows",
    "headers": [
      {
        "level": 2,
        "title": "Installing .cab drivers",
        "slug": "installing-cab-drivers",
        "link": "#installing-cab-drivers",
        "children": []
      },
      {
        "level": 2,
        "title": "Paid Drivers",
        "slug": "paid-drivers",
        "link": "#paid-drivers",
        "children": [
          {
            "level": 3,
            "title": "What drivers do I need to buy?",
            "slug": "what-drivers-do-i-need-to-buy",
            "link": "#what-drivers-do-i-need-to-buy",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/installing/installing-windows.html",
    "pathLocale": "/",
    "extraFields": [
      "Installing Windows"
    ]
  },
  {
    "title": "Known Issues",
    "headers": [
      {
        "level": 2,
        "title": "Operating Systems",
        "slug": "operating-systems",
        "link": "#operating-systems",
        "children": [
          {
            "level": 3,
            "title": "Windows",
            "slug": "windows",
            "link": "#windows",
            "children": []
          },
          {
            "level": 3,
            "title": "Linux",
            "slug": "linux",
            "link": "#linux",
            "children": []
          },
          {
            "level": 3,
            "title": "macOS",
            "slug": "macos",
            "link": "#macos",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/installing/known-issues.html",
    "pathLocale": "/",
    "extraFields": [
      "Known Issues"
    ]
  },
  {
    "title": "macOS Firmware",
    "headers": [
      {
        "level": 2,
        "title": "Custom Firmware",
        "slug": "custom-firmware",
        "link": "#custom-firmware",
        "children": [
          {
            "level": 3,
            "title": "Prebuilt",
            "slug": "prebuilt",
            "link": "#prebuilt",
            "children": []
          },
          {
            "level": 3,
            "title": "Building Manually",
            "slug": "building-manually",
            "link": "#building-manually",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Flashing Firmware",
        "slug": "flashing-firmware",
        "link": "#flashing-firmware",
        "children": []
      }
    ],
    "path": "/docs/installing/macos-firmware.html",
    "pathLocale": "/",
    "extraFields": [
      "macOS Firmware"
    ]
  },
  {
    "title": "macOS Hibernation",
    "headers": [
      {
        "level": 2,
        "title": "Testing Hibernation",
        "slug": "testing-hibernation",
        "link": "#testing-hibernation",
        "children": [
          {
            "level": 3,
            "title": "macOS enters S3 sleep instead of shutting down",
            "slug": "macos-enters-s3-sleep-instead-of-shutting-down",
            "link": "#macos-enters-s3-sleep-instead-of-shutting-down",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Hibernate Modes",
        "slug": "hibernate-modes",
        "link": "#hibernate-modes",
        "children": []
      }
    ],
    "path": "/docs/installing/macos-hibernation.html",
    "pathLocale": "/",
    "extraFields": [
      "macOS Hibernation"
    ]
  },
  {
    "title": "Post Install",
    "headers": [
      {
        "level": 2,
        "title": "Windows",
        "slug": "windows",
        "link": "#windows",
        "children": [
          {
            "level": 3,
            "title": "Drivers and QOL improvements",
            "slug": "drivers-and-qol-improvements",
            "link": "#drivers-and-qol-improvements",
            "children": []
          },
          {
            "level": 3,
            "title": "CELES Post Install Workaround (Windows)",
            "slug": "celes-post-install-workaround-windows",
            "link": "#celes-post-install-workaround-windows",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Linux",
        "slug": "linux",
        "link": "#linux",
        "children": [
          {
            "level": 3,
            "title": "General QOL Fixes",
            "slug": "general-qol-fixes",
            "link": "#general-qol-fixes",
            "children": []
          },
          {
            "level": 3,
            "title": "Fixing USB C on TigerLake and AlderLake",
            "slug": "fixing-usb-c-on-tigerlake-and-alderlake",
            "link": "#fixing-usb-c-on-tigerlake-and-alderlake",
            "children": []
          },
          {
            "level": 3,
            "title": "CELES Post Install Workaround Possible (Linux)",
            "slug": "celes-post-install-workaround-possible-linux",
            "link": "#celes-post-install-workaround-possible-linux",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "macOS",
        "slug": "macos",
        "link": "#macos",
        "children": []
      }
    ],
    "path": "/docs/installing/post-install.html",
    "pathLocale": "/",
    "extraFields": [
      "Post Install"
    ]
  },
  {
    "title": "Booting the Recovery USB",
    "headers": [],
    "path": "/docs/reverting/booting-recovery-usb.html",
    "pathLocale": "/",
    "extraFields": [
      "Booting the Recovery USB"
    ]
  },
  {
    "title": "Flashing Stock Firmware",
    "headers": [],
    "path": "/docs/reverting/flashing-stock.html",
    "pathLocale": "/",
    "extraFields": [
      "Flashing Stock Firmware"
    ]
  },
  {
    "title": "Reverting to Stock chromeOS",
    "headers": [
      {
        "level": 2,
        "title": "Requirements",
        "slug": "requirements",
        "link": "#requirements",
        "children": []
      }
    ],
    "path": "/docs/reverting/",
    "pathLocale": "/",
    "extraFields": [
      "Reverting to Stock chromeOS"
    ]
  },
  {
    "title": "Making a Recovery USB",
    "headers": [
      {
        "level": 2,
        "title": "Chromebook Recovery Utility (Windows/ChromeOS/macOS)",
        "slug": "chromebook-recovery-utility-windows-chromeos-macos",
        "link": "#chromebook-recovery-utility-windows-chromeos-macos",
        "children": []
      },
      {
        "level": 2,
        "title": "Manually Flash Recovery Image (Windows/ChromeOS/macOS/Linux)",
        "slug": "manually-flash-recovery-image-windows-chromeos-macos-linux",
        "link": "#manually-flash-recovery-image-windows-chromeos-macos-linux",
        "children": [
          {
            "level": 3,
            "title": "Windows",
            "slug": "windows",
            "link": "#windows",
            "children": []
          },
          {
            "level": 3,
            "title": "macOS/ChromeOS/Linux",
            "slug": "macos-chromeos-linux",
            "link": "#macos-chromeos-linux",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/reverting/making-recovery-usb.html",
    "pathLocale": "/",
    "extraFields": [
      "Making a Recovery USB"
    ]
  },
  {
    "title": "Unbricking",
    "headers": [
      {
        "level": 2,
        "title": "Unbricking with a ch341a USB programmer",
        "slug": "unbricking-with-a-ch341a-usb-programmer",
        "link": "#unbricking-with-a-ch341a-usb-programmer",
        "children": []
      },
      {
        "level": 2,
        "title": "Unbricking with a Suzy-Q cable",
        "slug": "unbricking-with-a-suzy-q-cable",
        "link": "#unbricking-with-a-suzy-q-cable",
        "children": []
      },
      {
        "level": 2,
        "title": "Unbricking with a flipper Zero",
        "slug": "unbricking-with-a-flipper-zero",
        "link": "#unbricking-with-a-flipper-zero",
        "children": []
      }
    ],
    "path": "/docs/unbricking/",
    "pathLocale": "/",
    "extraFields": [
      "Unbricking"
    ]
  },
  {
    "title": "Unbricking/Flashing with a ch341a USB programmer",
    "headers": [
      {
        "level": 2,
        "title": "Requirements",
        "slug": "requirements",
        "link": "#requirements",
        "children": []
      },
      {
        "level": 2,
        "title": "Hardware Disassembly",
        "slug": "hardware-disassembly",
        "link": "#hardware-disassembly",
        "children": []
      },
      {
        "level": 2,
        "title": "Prepping to Flash",
        "slug": "prepping-to-flash",
        "link": "#prepping-to-flash",
        "children": [
          {
            "level": 3,
            "title": "Persisting the board's Vital Product Data (VPD)",
            "slug": "persisting-the-board-s-vital-product-data-vpd",
            "link": "#persisting-the-board-s-vital-product-data-vpd",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Flashing Your Device",
        "slug": "flashing-your-device",
        "link": "#flashing-your-device",
        "children": [
          {
            "level": 3,
            "title": "Clean Up",
            "slug": "clean-up",
            "link": "#clean-up",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/unbricking/unbrick-ch341a.html",
    "pathLocale": "/",
    "extraFields": [
      "Unbricking/Flashing with a ch341a USB programmer"
    ]
  },
  {
    "title": "Unbricking with a Flipper Zero",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Preparations",
        "slug": "preparations",
        "link": "#preparations",
        "children": [
          {
            "level": 3,
            "title": "Step 1",
            "slug": "step-1",
            "link": "#step-1",
            "children": []
          },
          {
            "level": 3,
            "title": "Step 2",
            "slug": "step-2",
            "link": "#step-2",
            "children": []
          },
          {
            "level": 3,
            "title": "Step 3",
            "slug": "step-3",
            "link": "#step-3",
            "children": []
          },
          {
            "level": 3,
            "title": "Step 4",
            "slug": "step-4",
            "link": "#step-4",
            "children": []
          },
          {
            "level": 3,
            "title": "Step 5",
            "slug": "step-5",
            "link": "#step-5",
            "children": []
          },
          {
            "level": 3,
            "title": "Step 6",
            "slug": "step-6",
            "link": "#step-6",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/unbricking/unbrick-flipper.html",
    "pathLocale": "/",
    "extraFields": [
      "Unbricking with a Flipper Zero"
    ]
  },
  {
    "title": "Unbricking/Flashing with a Suzy-Q cable",
    "headers": [
      {
        "level": 2,
        "title": "Requirements",
        "slug": "requirements",
        "link": "#requirements",
        "children": []
      },
      {
        "level": 2,
        "title": "Hardware Disassembly",
        "slug": "hardware-disassembly",
        "link": "#hardware-disassembly",
        "children": []
      },
      {
        "level": 2,
        "title": "Prepping to Flash",
        "slug": "prepping-to-flash",
        "link": "#prepping-to-flash",
        "children": [
          {
            "level": 3,
            "title": "Persisting the board's Vital Product Data (VPD)",
            "slug": "persisting-the-board-s-vital-product-data-vpd",
            "link": "#persisting-the-board-s-vital-product-data-vpd",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Flashing Your Device",
        "slug": "flashing-your-device",
        "link": "#flashing-your-device",
        "children": [
          {
            "level": 3,
            "title": "Clean Up",
            "slug": "clean-up",
            "link": "#clean-up",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/unbricking/unbrick-suzyq.html",
    "pathLocale": "/",
    "extraFields": [
      "Unbricking/Flashing with a Suzy-Q cable"
    ]
  },
  {
    "title": "",
    "headers": [],
    "path": "/404.html",
    "pathLocale": "/",
    "extraFields": [
      ""
    ]
  }
]
